<template>
  <!-- thank-you START -->
  <div class="purchase-thank-you">
    <!-- thank-you:icon START -->
    <div class="icon">
      <img :src="iconHandshake" />
    </div>
    <!-- thank-you:icon END -->

    <!-- thank-you:headline START -->
    <h1 v-html="`${$t('cart.thankYouForPurchase')}!`"></h1>
    <!-- thank-you:headline END -->

    <!-- thank-you:description START -->
    <div
      class="description"
      v-html="$t('cart.thankyouforpurchasedescription')"
    ></div>
    <!-- thank-you:description END -->

    <!-- thank-you:button START -->
    <router-link
        :to="'/account'"
        class="btn-default btn-default-account-icon"
      >
        <span
          v-html="`${$t('cart.touseraccount')}`"
        ></span>
      </router-link>
    <!-- thank-you:button END -->
  </div>
  <!-- thank-you END -->
</template>

<script>
import { mapActions } from 'vuex';
import ICONS from '../../utils/icons';

const {
  iHandshake,
} = ICONS;

export default {
  name: 'purchase-thank-you',
  data() {
    return {
      iconHandshake: localStorage && localStorage.getItem('theme') === 'light' ? iHandshake.defaultUrl : iHandshake.greenUrl,
    };
  },
  methods: {
    ...mapActions([
      'clearCart',
    ]),
  },
  created() {
    this.clearCart();
  },
  computed: {
    cart() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.items;
      }
      return this.$store.state.storeCart;
    },
  },
};
</script>
